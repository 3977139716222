import React, { useEffect } from 'react'
import useNativeLazyLoading from '@charlietango/use-native-lazy-loading'
import { useInView } from 'react-intersection-observer'

export default function LazyImage (props) {
  const supportsLazyLoading = useNativeLazyLoading()
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px'
  })
  const { imageClass, imageId, src, alt, containClass } = props

  return !props.background ? (
    <React.Fragment>
      <div
        ref={!supportsLazyLoading ? ref : undefined}
        className={` image-container ${containClass ? containClass : ''}`}
      >
        {inView || supportsLazyLoading ? (
          <img
            className={` ${imageClass ? imageClass : ''} `}
            id={imageId ? imageId : ''}
            alt={alt ? alt : ''}
            src={src}
            loading='lazy'
          />
        ) : null}
      </div>
      {props.children}
      <style jsx>{`
        .image-container {
          height: 100%;
          width: 100%;
        }
      `}</style>
    </React.Fragment>
  ) : (
    //If background image, adding background prop uses div instead of img element
    <React.Fragment>
      <div
        ref={!supportsLazyLoading ? ref : undefined}
        className={` image-container ${containClass ? containClass : ''}`}
      >
        {inView || supportsLazyLoading ? (
          <div
            className={` bg-image ${imageClass ? imageClass : ''} `}
            id={imageId ? imageId : ''}
            loading='lazy'
          >
            {props.children}
          </div>
        ) : null}
      </div>
      <style jsx>{`
        .image-container {
          width: 100%;
        }
        .bg-image {
          position: relative;
          background-image: url(${src});
          background-size: cover;
          width: 100%;
        }
      `}</style>
    </React.Fragment>
  )
}
